App.css
.App {
  text-align: center;
}
 
.App-logo {
  height: 40vmin;
}
 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
 
.App-link {
  color: #09d3ac;
}

.card {
  margin:5px;
}
 
body{
  background: #ececec;
  background-image:url('./assets/hlbg1.png');
  background-repeat:no-repeat;
  background-size:contain;
  /* background-size:cover; */
}

overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,1); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}


.img-responsive{
  display: block;
  width: 100%;
  height: auto;
}
.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}

.sliderrd{
  
  border-radius: 15px;
}


.slides {
  position: relative;
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index:1;
}

.slides .slick-prev {
  left: 5%;
}

.slides .slick-next {
  right: 5%;
}